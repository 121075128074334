import { Box, Button, CssBaseline, TextField, Typography, Grid, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as jwt from "jwt-decode";
import { styled } from "@mui/system";
import { StyledContainer } from "../../helper/utils";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: 'rgba(255, 251, 240, 0.95)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
  width: '90%',
  maxWidth: '1000px',
  margin: '20px auto',
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  }
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    '&.Mui-focused': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    }
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(1),
  fontSize: '1.1rem',
  textTransform: 'none',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  '&:hover': {
    boxShadow: '0 4px 6px rgba(0,0,0,0.15)',
  }
}));

const UserInfoRegPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const phone = queryParams.get("phone");
  const role_option = queryParams.get("role_option");
  const tokenFromUrl = queryParams.get("makeichq");
  const payment_enabled = queryParams.get("payment_enabled");
  const date = queryParams.get("date");
  const adminId = queryParams.get("payid");

  // Token expiration check
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwt.jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;  // `exp` is the expiration time in JWT
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };

  const [fieldConfig, setFieldConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isTokenExpired(tokenFromUrl)) {
      navigate("/oops");
    }
  }, [tokenFromUrl, navigate]);

  useEffect(() => {
    const fetchFieldConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/field_config/${adminId}`
        );
        setFieldConfig(response.data.config);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching field config:', error);
      }
    };

    if (adminId) {
      fetchFieldConfig();
    }
  }, [adminId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    // Create an object to store all field values
    const formFields = {};
    
    // Collect all enabled field values and handle custom fields
    Object.entries(fieldConfig).forEach(([fieldName, config]) => {
      if (config.enabled) {
        const value = data.get(fieldName) || '';
        if (fieldName.startsWith('custom')) {
          // Store custom field with its label
          formFields[fieldName] = {
            value: value,
            label: config.label
          };
        } else {
          formFields[fieldName] = value;
        }
      }
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/update_info`,
        {
          user_info: formFields,
          payment_enabled: payment_enabled,
          role_option: role_option,
          phone: phone,
          admin_id: adminId
        }
      );
      
      if (response.data.data === 200) {
        alert("Saved Ok");
        localStorage.setItem("payid", adminId)
        localStorage.setItem("phone", phone);
        localStorage.setItem("customerId", phone);
        localStorage.setItem("role_option", role_option);
        localStorage.setItem("date", date);
        console.log("🚀 ~ handleSubmit ~ role_option:", role_option)
        console.log("🚀 ~ handleSubmit ~ payment_enabled:", payment_enabled)
        if (payment_enabled == "1" && role_option == "2") {
          navigate("/pay_stripe");
        }
        if (payment_enabled == "1" && role_option == "1") {
          navigate("/pay_stripe");
        }
        if (payment_enabled == "0" && role_option == "2") {
          navigate("/check_game");
        }
        if (payment_enabled == "0" && role_option == "1") {
          navigate("/check_game");
        }
      } else if (response.data.data === 201) {
        alert(
          `The customer already exists!.\n If you wish to update the appointment date or role option, please delete the customer's data in admin page and re-register.`
        );
      }
    } catch (err) {
      console.error('Submit error:', err);
      alert("Saved Failed");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <StyledContainer>
      <CssBaseline />
      <StyledPaper>
        <Typography 
          component="h1" 
          variant="h4" 
          sx={{ 
            mb: 4, 
            textAlign: 'center',
            color: '#2c3e50',
            fontWeight: 600
          }}
        >
          {t("regist-info")}
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {fieldConfig && Object.entries(fieldConfig).map(([fieldName, config]) => {
              if (!config.enabled) return null;

              const fieldProps = {
                required: config.required,
                fullWidth: true,
                id: fieldName,
                name: fieldName,
                label: config.label || t(`admin-field-${fieldName}`),
                variant: "outlined",
                sx: { mb: 2 }
              };

              if (fieldName === 'birthday') {
                return (
                  <Grid item xs={12} sm={6} key={fieldName}>
                    <StyledTextField
                      {...fieldProps}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                );
              }

              return (
                <Grid item xs={12} sm={6} key={fieldName}>
                  <StyledTextField {...fieldProps} />
                </Grid>
              );
            })}
          </Grid>
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            {t("submit")}
          </StyledButton>
        </StyledForm>
      </StyledPaper>
    </StyledContainer>
  );
};

export default UserInfoRegPage;
