import React, { useState, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { TextField, Box, Badge, Divider, Select, MenuItem, InputLabel, Checkbox, CircularProgress, FormControl, RadioGroup, FormControlLabel, Radio, Chip, Snackbar, Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { AppContext } from "../../context/app-context";
import FormFieldsDialog from './FormFieldsDialog';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const StyledBadge = styled(Badge)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: theme.spacing(2),
  textAlign: "left",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  maxWidth: "150px",
  margin: "auto",
  position: "absolute",
  right: 5,
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    paddingRight: theme.spacing(2),
  },
  "& .MuiOutlinedInput-input": {
    padding: theme.spacing(1.5, 4),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CodeBlock = styled(Box)(({ theme }) => ({
  backgroundColor: "#e3e8ef",
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  fontFamily: "monospace",
  whiteSpace: "pre-wrap",
  overflowX: "auto",
  "& code": {
    display: "block",
  },
}));

const ApiDocPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: "#f5f7fa",
  "& ul": {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  "& li": {
    marginBottom: theme.spacing(1),
  },
}));

const EnhancedTableHead = (props) => {
  const { t } = useTranslation();

  const headCells = [
    {
      id: "option",
      numeric: true,
      disablePadding: false,
      label: t("admin-option"),
    },
    {
      id: "appointmentdate",
      numeric: true,
      disablePadding: false,
      label: t("admin-date"),
    },
    {
      id: "phone",
      numeric: true,
      disablePadding: false,
      label: t("admin-phone"),
    },
    {
      id: "firstname",
      numeric: true,
      disablePadding: false,
      label: t("admin-firstname"),
    },
    {
      id: "lastname",
      numeric: true,
      disablePadding: false,
      label: t("admin-lastname"),
    },
    {
      id: "birthday",
      numeric: true,
      disablePadding: false,
      label: t("admin-birth"),
    },
    {
      id: "personid",
      numeric: true,
      disablePadding: false,
      label: t("admin-person"),
    },
    {
      id: "email",
      numeric: true,
      disablePadding: false,
      label: t("admin-email"),
    },
    {
      id: "city",
      numeric: true,
      disablePadding: false,
      label: t("admin-city"),
    },
    {
      id: "street",
      numeric: true,
      disablePadding: false,
      label: t("admin-street"),
    },
    {
      id: "housenum",
      numeric: true,
      disablePadding: false,
      label: t("admin-housenum"),
    },
    {
      id: "code",
      numeric: true,
      disablePadding: false,
      label: t("admin-zip"),
    },
    {
      id: "gamestatus",
      numeric: true,
      disablePadding: false,
      label: t("admin-gamestatus"),
    },
    {
      id: "paystatus",
      numeric: true,
      disablePadding: false,
      label: "admin-paystatus",
    },
    // {
    //   id: "reminder-status",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "reminder-status",
    // },
    {
      id: "extra-info",
      numeric: true,
      disablePadding: false,
      label: "Extra Info",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "admin-action",
    },
  ];

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

// ReminderTableHead component remains unchanged
const ReminderTableHead = (props) => {
  const headCells = [
    {
      id: "phone",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "reservedate",
      numeric: true,
      disablePadding: false,
      label: "Reminder Date & Time",
    },
  ];
  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
};

const EnhancedTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [reminderOrder, setReminderOrder] = useState("asc");
  const [reminderOrderBy, setReminderOrderBy] = useState("calories");
  const [reminderPage, setReminderPage] = useState(0);
  const [reminderDense, setReminderDense] = useState(false);
  const [reminderRowsPerPage, setReminderRowsPerPage] = useState(5);

  const [adminData, setAdminData] = useState([]);
  const [filterKey, setFiliterKey] = useState("");
  const [filterReminder, setFiliterReminder] = useState("");
  const [userData, setUserData] = useState([]);
  const [reminderData, setReminderData] = useState([]);
  const [adminPassword, setAdminPassword] = useState("");
  const [adminPrice_1, setAdminPrice_1] = useState(0);
  const [adminPrice_2, setAdminPrice_2] = useState(0);
  const [removeId, setRemoveId] = useState(0);
  const [openDlg, setOpenDlg] = useState(false);
  const [price1Dlg, setPrice1Dlg] = useState(false);
  const [price2Dlg, setPrice2Dlg] = useState(false);
  const [passDlg, setPassDlg] = useState(false);
  const [remDlg, setRemDlg] = useState(false);
  const [smsTextDlg, setSmsTxtDlg] = useState(false);
  const [stripePubDlg, setStripePubDlg] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [rem_1, setRem_1] = useState(true);
  const [rem_2, setRem_2] = useState(false);
  const [rem_3, setRem_3] = useState(false);
  const [rem_1_per, setRem_1_per] = useState(false);
  const [rem_2_per, setRem_2_per] = useState(false);
  const [rem_3_per, setRem_3_per] = useState(false);
  const [phVeriText, setPhVeriText] = useState("");
  const [noRegText, setNoRegText] = useState("");
  const [appoiText, setAppoiText] = useState("");
  const [stripePubKey, setStripePubKey] = useState("");
  const [quant, setQuant] = useState(0);
  const [progressData, setProgressData] = useState([]);
  const { adminId } = useContext(AppContext);
  const [formFieldsOpen, setFormFieldsOpen] = useState(false);
  const [reminderType, setReminderType] = useState('percentage');
  const [reminder1, setReminder1] = useState(false);
  const [reminder2, setReminder2] = useState(false);
  const [reminder3, setReminder3] = useState(false);
  const [reminder1Per, setReminder1Per] = useState(0);
  const [reminder2Per, setReminder2Per] = useState(0);
  const [reminder3Per, setReminder3Per] = useState(0);
  const [reminder1Hours, setReminder1Hours] = useState(0);
  const [reminder2Hours, setReminder2Hours] = useState(0);
  const [reminder3Hours, setReminder3Hours] = useState(0);
  const [reminder1Text, setReminder1Text] = useState("");
  const [reminder2Text, setReminder2Text] = useState("");
  const [reminder3Text, setReminder3Text] = useState("");
  const [apiCredentials, setApiCredentials] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    const calculateProgress = () => {
      const updatedProgress = userData.map((user) => {
        // Extract user-specific data
        const { rem_1_rec, rem_2_rec, rem_3_rec } = user;

        // Extract admin-specific data (rem_1_per, rem_2_per, rem_3_per)
        const { rem_1_per, rem_2_per, rem_3_per } = adminData  // assuming adminData is an array with one object

        let totalProgress = 0;

        if (rem_1_rec === 1) totalProgress += rem_1_per;
        if (rem_2_rec === 1) totalProgress += rem_2_per;
        if (rem_3_rec === 1) totalProgress += rem_3_per;

        // Ensure the total progress doesn't exceed 100%
        totalProgress = Math.min(totalProgress, 100);

        return { userId: user.user_id, progress: totalProgress };
      });

      setProgressData(updatedProgress);
    };

    calculateProgress();
  }, [userData, adminData]);

  useEffect(() => {
    setRem_1(adminData.rem_1)
    setRem_2(adminData.rem_2)
    setRem_3(adminData.rem_3)
    setPhVeriText(adminData.ph_veri_txt)
    setNoRegText(adminData.no_reg_txt)
    setStripePubKey(adminData.stripe_pub_key)
    setRem_1_per(adminData.rem_1_per)
    setRem_2_per(adminData.rem_2_per)
    setRem_3_per(adminData.rem_3_per)
  }, [adminData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const admin_info = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/get_admin_info`, { adminId }
        );
        if (admin_info.status === 200) {
          setAdminData(admin_info.data.data[0]);
        } else {
          alert(t("admin-error"));
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user_info = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/get_user_info`,
          {
            filterKey,
          }
        );
        if (user_info.status === 200) {
          setPage(0);
          setUserData(user_info.data.data);
        } else {
          alert("Error!");
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [filterKey]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const reminder_info = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/get_reminder_info`,
          {
            filterReminder,
          }
        );
        if (reminder_info.status === 200) {
          setReminderData(reminder_info.data.data);
        } else {
          alert("Error!");
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [filterReminder]);

  useEffect(() => {
    const fetchReminderSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/reminder-settings/${adminId}`
        );
        const settings = response.data;

        setReminderType(settings.reminder_type || 'percentage');
        setReminder1(settings.rem_1 === 1);
        setReminder2(settings.rem_2 === 1);
        setReminder3(settings.rem_3 === 1);
        setReminder1Per(settings.rem_1_per || 0);
        setReminder2Per(settings.rem_2_per || 0);
        setReminder3Per(settings.rem_3_per || 0);
        setReminder1Hours(settings.rem_1_hours || 0);
        setReminder2Hours(settings.rem_2_hours || 0);
        setReminder3Hours(settings.rem_3_hours || 0);

        setReminder1Text(settings.reminder1_text || '');
        setReminder2Text(settings.reminder2_text || '');
        setReminder3Text(settings.reminder3_text || '');
      } catch (error) {
        console.error('Error fetching reminder settings:', error);
      }
    };

    if (adminId) {
      fetchReminderSettings();
    }
  }, [adminId]);

  const serachUser = async (event) => {
    if (event.key === "Enter") {
      setFiliterKey(event.target.value);
    }
  };
  const serachReminder = async (event) => {
    if (event.key === "Enter") {
      setFiliterReminder(event.target.value);
    }
  };

  const removeRow = async (row) => {
    try {
      const user_info = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/remove_user`,
        {
          row,
        }
      );
      if (user_info.status === 200) {
        setOpenDlg(false);
        setUserData(user_info.data.data);
        alert("Deleted exactly!");
      } else {
        alert(t("admin-error"));
      }
    } catch (err) {
      alert(err);
    }
  };

  const changePassword = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/change_password`,
        {
          adminPassword,
          adminId
        }
      );
      if (response.status === 200) {
        setPassDlg(false);
        alert(t("admin-passchanged"));
      } else {
        alert(t("admin-error"));
      }
    } catch (err) {
      alert(err);
    }
  };
  const changePrice_1 = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/change_price_1`,
        {
          adminPrice_1,
          adminId
        }
      );
      if (response.status === 200) {
        setPrice1Dlg(false);
        alert(t("admin-pricechagned1"));
      } else {
        alert(t("admin-error"));
      }
    } catch (err) {
      alert(err);
    }
  };

  const changePrice_2 = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/change_price_2`,
        {
          adminPrice_2,
          adminId
        }
      );
      if (response.status === 200) {
        setPrice2Dlg(false);
        alert(t("admin-pricechagned2"));
      } else {
        alert(t("admin-error"));
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleReminderSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/change_appoi_rem`,
        {
          reminder1,
          reminder2,
          reminder3,
          reminder1_per: reminderType === 'percentage' ? reminder1Per : null,
          reminder2_per: reminderType === 'percentage' ? reminder2Per : null,
          reminder3_per: reminderType === 'percentage' ? reminder3Per : null,
          reminder1_hours: reminderType === 'hours' ? reminder1Hours : null,
          reminder2_hours: reminderType === 'hours' ? reminder2Hours : null,
          reminder3_hours: reminderType === 'hours' ? reminder3Hours : null,
          reminder_type: reminderType,
          reminder1_text: reminder1Text,
          reminder2_text: reminder2Text,
          reminder3_text: reminder3Text,
          id: adminId
        }
      );
      if (response.data.data === 200) {
        alert(t("admin-reminder-success"));
      }
    } catch (err) {
      console.error(err);
      alert(t("admin-reminder-fail"));
    }
  };

  const handleReminderTextUpdate = async () => {
    try {
      console.log(phVeriText, noRegText, appoiText)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/change_rem_sms_txt`,
        {
          ph_veri_txt: phVeriText,
          no_reg_txt: noRegText,
          id: adminId
        }
      );

      if (response.status === 200) {
        alert("Reminder SMS Texts updated successfully.");
      } else {
        alert("An error occurred while updating the reminders sms text.");
      }
    } catch (err) {
      console.error(err);
      alert("Error: " + err.message);
    }
  };

  const handleStripePubUpdate = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/change_stripe_pub_key`,
        {
          stripe_pub_key: stripePubKey,
          id: adminId
        }
      );

      if (response.status === 200) {
        alert("your Key updated successfully.");
      } else {
        alert("An error occurred while updating stripe key.");
      }
    } catch (err) {
      console.error(err);
      alert("Error: " + err.message);
    }
  };

  const generateApiCredentials = async () => {
    try {
      const adminId = localStorage.getItem('adminId'); // Get adminId from localStorage

      if (!adminId) {
        setSnackbar({
          open: true,
          message: 'Admin ID not found. Please login again.',
          severity: 'error'
        });
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/appointments/credentials/generate`,
        { adminId }
      );

      if (response.data.success) {
        setApiCredentials(response.data.data);
        setSnackbar({
          open: true,
          message: 'API credentials generated successfully!',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error generating API credentials:', error);
      setSnackbar({
        open: true,
        message: 'Failed to generate API credentials',
        severity: 'error'
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleReminderChangePage = (event, newPage) => {
    setReminderPage(newPage);
  };

  const handleReminderChangeRowsPerPage = (event) => {
    setReminderRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hanldeTabValue = (event, v) => {
    setTabValue(v);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userData.length) : 0;

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  const stableSort = (array, comparator) => {
    if (!Array.isArray(array)) {
      console.error("Expected an array but got", typeof array);
      return [];
    }

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const visibleRows = useMemo(() => {
    console.log("User Data:", userData); // Log user data
    return stableSort(userData, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rowsPerPage, userData]);

  const visibleReminders = useMemo(
    () =>
      stableSort(reminderData, getComparator(reminderOrder, reminderOrderBy)).slice(
        reminderPage * reminderRowsPerPage,
        reminderPage * reminderRowsPerPage + reminderRowsPerPage
      ),
    [
      reminderOrder,
      reminderOrderBy,
      reminderPage,
      reminderRowsPerPage,
      reminderData,
    ]
  );

  const payload = {
    adminId: adminData.id,
    email: adminData.email,
    quant,
    date: new Date().toISOString(),
  };
  console.log("🚀 ~ EnhancedTable ~ payload:", payload)
  //handling payment for credits purchase
  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/stripe/create-credit-checkout-session`,
      {
        payload,
      }
    );
    const { id } = response.data;

    const result = await stripe.redirectToCheckout({
      sessionId: id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  const VariableHelperText = () => (
    <Box
      sx={{
        mt: 1,
        p: 2,
        borderRadius: 1,
        backgroundColor: '#f5f7fa',
        border: '1px solid #e1e8ef',
        '@media (max-width: 600px)': {
          p: 1.5,
        }
      }}
    >
      <Typography
        variant="subtitle2"
        color="primary"
        sx={{
          mb: 1,
          fontWeight: 600,
          '@media (max-width: 600px)': {
            fontSize: '0.8rem',
          }
        }}
      >
        Available Variables:
      </Typography>
      <Grid container spacing={1}>
        {[
          { label: 'First Name', value: '{first_name}' },
          { label: 'Last Name', value: '{last_name}' },
          { label: 'Appointment Date', value: '{appointment_date}' },
          { label: 'Appointment Time', value: '{appointment_time}' }
        ].map((variable) => (
          <Grid item xs={12} sm={6} md={3} key={variable.value}>
            <Chip
              label={
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                    fontSize: '0.75rem',
                  }
                }}>
                  <Typography variant="caption" color="textSecondary">
                    {variable.label}
                  </Typography>
                  <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                    {variable.value}
                  </Typography>
                </Box>
              }
              sx={{
                width: '100%',
                height: 'auto',
                p: 1,
                backgroundColor: '#ffffff',
                border: '1px dashed #cbd5e1',
                '&:hover': {
                  backgroundColor: '#f8fafc',
                  cursor: 'pointer',
                },
                '@media (max-width: 600px)': {
                  p: 0.5,
                }
              }}
              onClick={() => {
                navigator.clipboard.writeText(variable.value);
                // Optional: Add a toast notification here
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          mt: 1,
          display: 'block',
          fontStyle: 'italic',
          '@media (max-width: 600px)': {
            fontSize: '0.7rem',
          }
        }}
      >
        Click on any variable to copy to clipboard
      </Typography>
    </Box>
  );

  return (
    <div style={{ position: "relative", top: "60px" }}>
      <AppBar
        position="static"
        sx={{ marginRight: "15px", marginLeft: "15px", width: "auto" }}
      >
        <Tabs
          sx={{ paddingLeft: "10px" }}
          value={tabValue}
          onChange={(e, v) => hanldeTabValue(e, v)}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="full width tabs example"
        >
          <Tab label="Manage your setup" id="tab1" />
          <Tab label="List of Customer" id="tab2" />
          {/* <Tab label="List of Reminder" id="tab3" /> */}
          <Tab label="Purchase SMS" id="tab4" />
          <Tab label="API Access" id="tab5" />
        </Tabs>
      </AppBar>
      {tabValue === 0 && (<Box sx={{ flexGrow: 1, margin: "30px 15px 15px 15px" }}>
        <Paper sx={{ padding: "15px 15px 15px 15px" }}>
          <Typography
            component="h1"
            variant="h5"
            align="left"
            marginBottom={"5px"}
          >
            {t("admin-header")}
          </Typography>
          <Divider sx={{ marginBottom: "25px" }}></Divider>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, md: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12} md={4}>
              <Item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("admin-password")}
                  type="password"
                  id="password"
                  autoComplete="password"
                  onChange={(e) => {
                    setAdminPassword(e.target.value);
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setPassDlg(true);
                  }}
                >
                  {t("admin-set")}
                </Button>
              </Item>
            </Grid>
            <Grid xs={12} md={4}>
              <Item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="price_1"
                  label={t("admin-price1") + adminData.price_1}
                  defaultValue={adminData.price_1}
                  type="number"
                  id="price_1"
                  autoComplete="Price of Option 1"
                  onChange={(e) => {
                    setAdminPrice_1(e.target.value);
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setPrice1Dlg(true);
                  }}
                >
                  {t("admin-set")}
                </Button>
              </Item>
            </Grid>
            <Grid xs={12} md={4}>
              <Item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="price_2"
                  label={t("admin-price2") + adminData.price_2}
                  defaultValue={adminData.price_2}
                  type="number"
                  id="price_2"
                  autoComplete="Price of Option 2"
                  onChange={(e) => {
                    setAdminPrice_2(e.target.value);
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setPrice2Dlg(true);
                  }}
                >
                  {t("admin-set")}
                </Button>
              </Item>
            </Grid>
            <Grid xs={12} md={5}>
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>{t("admin-reminder-settings")}</p>
              <Item>
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                  <RadioGroup
                    row
                    value={reminderType}
                    onChange={(e) => setReminderType(e.target.value)}
                  >
                    <FormControlLabel
                      value="percentage"
                      control={<Radio />}
                      label="Percentage of Time"
                    />
                    <FormControlLabel
                      value="hours"
                      control={<Radio />}
                      label="Hours Prior"
                    />
                  </RadioGroup>
                </FormControl>

                <Grid container spacing={2}>
                  {/* Reminder 1 */}
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reminder1}
                          onChange={(e) => setReminder1(e.target.checked)}
                        />
                      }
                      label={t("admin-reminder-1")}
                    />
                    {reminderType === 'percentage' ? (
                      <>
                        <TextField
                          type="number"
                          label={t("admin-reminder-percentage")}
                          value={reminder1Per}
                          onChange={(e) => setReminder1Per(e.target.value)}
                          disabled={!reminder1}
                          InputProps={{
                            inputProps: { min: 0, max: 100 }
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label={t("admin-reminder-1-text")}
                          value={reminder1Text}
                          onChange={(e) => setReminder1Text(e.target.value)}
                          margin="normal"
                          disabled={!reminder1}
                          multiline
                          rows={3}
                          placeholder={t("admin-reminder-1-text-placeholder")}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          type="number"
                          label={t("admin-reminder-hours")}
                          value={reminder1Hours}
                          onChange={(e) => setReminder1Hours(e.target.value)}
                          disabled={!reminder1}
                          InputProps={{
                            inputProps: { min: 1 }
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label={t("admin-reminder-1-text")}
                          value={reminder1Text}
                          onChange={(e) => setReminder1Text(e.target.value)}
                          margin="normal"
                          disabled={!reminder1}
                          multiline
                          rows={3}
                          placeholder={t("admin-reminder-1-text-placeholder")}
                        />
                      </>
                    )}
                  </Grid>

                  {/* Reminder 2 */}
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reminder2}
                          onChange={(e) => setReminder2(e.target.checked)}
                        />
                      }
                      label={t("admin-reminder-2")}
                    />
                    {reminderType === 'percentage' ? (
                      <>
                        <TextField
                          type="number"
                          label={t("admin-reminder-percentage")}
                          value={reminder2Per}
                          onChange={(e) => setReminder2Per(e.target.value)}
                          disabled={!reminder2}
                          InputProps={{
                            inputProps: { min: 0, max: 100 }
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label={t("admin-reminder-2-text")}
                          value={reminder2Text}
                          onChange={(e) => setReminder2Text(e.target.value)}
                          margin="normal"
                          disabled={!reminder2}
                          multiline
                          rows={3}
                          placeholder={t("admin-reminder-2-text-placeholder")}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          type="number"
                          label={t("admin-reminder-hours")}
                          value={reminder2Hours}
                          onChange={(e) => setReminder2Hours(e.target.value)}
                          disabled={!reminder2}
                          InputProps={{
                            inputProps: { min: 1 }
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label={t("admin-reminder-2-text")}
                          value={reminder2Text}
                          onChange={(e) => setReminder2Text(e.target.value)}
                          margin="normal"
                          disabled={!reminder2}
                          multiline
                          rows={3}
                          placeholder={t("admin-reminder-2-text-placeholder")}
                        />
                      </>
                    )}
                  </Grid>

                  {/* Reminder 3 */}
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={reminder3}
                          onChange={(e) => setReminder3(e.target.checked)}
                        />
                      }
                      label={t("admin-reminder-3")}
                    />
                    {reminderType === 'percentage' ? (
                      <>
                        <TextField
                          type="number"
                          label={t("admin-reminder-percentage")}
                          value={reminder3Per}
                          onChange={(e) => setReminder3Per(e.target.value)}
                          disabled={!reminder3}
                          InputProps={{
                            inputProps: { min: 0, max: 100 }
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label={t("admin-reminder-3-text")}
                          value={reminder3Text}
                          onChange={(e) => setReminder3Text(e.target.value)}
                          margin="normal"
                          disabled={!reminder3}
                          multiline
                          rows={3}
                          placeholder={t("admin-reminder-3-text-placeholder")}
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          type="number"
                          label={t("admin-reminder-hours")}
                          value={reminder3Hours}
                          onChange={(e) => setReminder3Hours(e.target.value)}
                          disabled={!reminder3}
                          InputProps={{
                            inputProps: { min: 1 }
                          }}
                          fullWidth
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label={t("admin-reminder-3-text")}
                          value={reminder3Text}
                          onChange={(e) => setReminder3Text(e.target.value)}
                          margin="normal"
                          disabled={!reminder3}
                          multiline
                          rows={3}
                          placeholder={t("admin-reminder-3-text-placeholder")}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
                <VariableHelperText />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReminderSubmit}
                  sx={{ mt: 2 }}
                  fullWidth
                >
                  {t("admin-save")}
                </Button>
              </Item>
            </Grid>
            <Grid xs={12} md={4}>
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>SMS Text</p>
              <Item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="ph_veri_txt"
                  label={`${phVeriText}-(Phone Verify SMS)`}
                  defaultValue={phVeriText}
                  type="text"
                  id="ph_veri_txt"
                  autoComplete="Phone verification SMS Text"
                  onChange={(e) => {
                    setPhVeriText(e.target.value);
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="no_reg_txt"
                  label={`${noRegText}-(Not Registered SMS)`}
                  defaultValue={noRegText}
                  type="text"
                  id="no_reg_txt"
                  autoComplete="Not Registered SMS Text"
                  onChange={(e) => {
                    setNoRegText(e.target.value);
                  }}
                />
                {/* <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="appoi_txt"
                  label={`${appoiText}-(Appointment SMS)`}
                  defaultValue={appoiText}
                  type="text"
                  id="appoi_txt"
                  autoComplete="Appointment SMS Text"
                  onChange={(e) => {
                    setAppoiText(e.target.value);
                  }}
                /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSmsTxtDlg(true);
                  }}
                >
                  {t("admin-set")}
                </Button>
              </Item>
            </Grid>
            <Grid xs={12} md={3}>
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>Stripe Key For Payment</p>
              <Item>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="stripe_pub_key"
                  label={stripePubKey ? `${stripePubKey}` : "Enter your stripe key"}
                  defaultValue={stripePubKey}
                  type="text"
                  id="stripe_pub_key"
                  autoComplete="Stripe Key For Payment"
                  onChange={(e) => {
                    setStripePubKey(e.target.value);
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setStripePubDlg(true);
                  }}
                >
                  {t("admin-set")}
                </Button>
              </Item>
            </Grid>
          </Grid>
        </Paper>
      </Box>)}
      {/* {
        tabValue === 2 && (
          <Box sx={{ flexGrow: 1, margin: "30px 15px 15px 15px" }}>
            <Paper sx={{ margin: "15px 15px 15px 15px" }}>
              <Toolbar
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <Typography variant="h6" id="tableTitle" component="div">
                  List of Reminder
                </Typography>
                <StyledTextField
                  variant="outlined"
                  placeholder={t("admin-search")}
                  onKeyDown={(e) => serachReminder(e)}
                />
              </Toolbar>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={reminderDense ? "small" : "medium"}
                >
                  <ReminderTableHead rowCount={reminderData.length} />
                  <TableBody>
                    {visibleReminders.map((row, index) => {
                      return (
                        <StyledTableRow
                          hover
                          tabIndex={-1}
                          key={row.reminder_id}
                          sx={{ cursor: "pointer" }}
                        >
                          <StyledTableCell align="center">
                            {row.phone}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.reserve_date.toString()}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (reminderDense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={reminderData.length}
                rowsPerPage={reminderRowsPerPage}
                page={reminderPage}
                onPageChange={handleReminderChangePage}
                onRowsPerPageChange={handleReminderChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )
      } */}
      {
        tabValue === 1 && (
          <>
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ margin: "15px 15px 15px 15px" }}>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <Button variant="contained" color="primary" onClick={() => navigate('/admin')}>Register Customer</Button>
                  <Button
                    variant="contained"
                    onClick={() => setFormFieldsOpen(true)}
                  >
                    {t("admin-form-fields")}
                  </Button>
                </Box>
                <Toolbar
                  sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                  }}
                >
                  <Typography variant="h6" id="tableTitle" component="div">
                    {t("admin-list")}
                  </Typography>
                  <StyledTextField
                    variant="outlined"
                    placeholder={t("admin-search")}
                    onKeyDown={(e) => serachUser(e)}
                  />
                </Toolbar>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead rowCount={userData.length} />
                    <TableBody>
                      {visibleRows.map((row, index) => {
                        const userProgress = progressData.find((data) => data.userId === row.user_id)?.progress || 0;
                        // Combine custom fields into a single string
                        const extraInfo = Object.values(row.custom_fields || {})
                          .map(({ label, value }) => `${label}: ${value}`)
                          .join(', ');

                        return (
                          <StyledTableRow
                            hover
                            tabIndex={-1}
                            key={row.user_id}
                            sx={{ cursor: "pointer" }}
                          >
                            <StyledTableCell align="center">
                              {row.role_option}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.appointmentdate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.phone}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.firstname}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.lastname}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.birthday}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.personid}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.email}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.city}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.street}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.housenum}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.zipcode}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box display="flex" justifyContent="center">
                                <StyledBadge
                                  badgeContent={row.gamestatus.split(".")[1]}
                                  color="primary"
                                >
                                  <LooksOneIcon
                                    color={
                                      row.gamestatus.split(".")[0][0] === "0"
                                        ? "fail"
                                        : "success"
                                    }
                                  />
                                </StyledBadge>
                                <StyledBadge
                                  badgeContent={row.gamestatus.split(".")[2]}
                                  color="primary"
                                >
                                  <LooksTwoIcon
                                    color={
                                      row.gamestatus.split(".")[0][1] === "0"
                                        ? "fail"
                                        : "success"
                                    }
                                  />
                                </StyledBadge>
                                <StyledBadge
                                  badgeContent={row.gamestatus.split(".")[3]}
                                  color="primary"
                                >
                                  <Looks3Icon
                                    color={
                                      row.gamestatus.split(".")[0][2] === "0"
                                        ? "fail"
                                        : "success"
                                    }
                                  />
                                </StyledBadge>
                                <StyledBadge
                                  badgeContent={row.gamestatus.split(".")[4]}
                                  color="primary"
                                >
                                  <Looks4Icon
                                    color={
                                      row.gamestatus.split(".")[0][3] === "0"
                                        ? "fail"
                                        : "success"
                                    }
                                  />
                                </StyledBadge>
                                <StyledBadge
                                  badgeContent={row.gamestatus.split(".")[5]}
                                  color="primary"
                                >
                                  <Looks5Icon
                                    color={
                                      row.gamestatus.split(".")[0][4] === "0"
                                        ? "fail"
                                        : "success"
                                    }
                                  />
                                </StyledBadge>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <MonetizationOnIcon
                                sx={
                                  row.paystatus === "1"
                                    ? { color: "gold" }
                                    : { color: "black" }
                                }
                              ></MonetizationOnIcon>
                            </StyledTableCell>
                            {/* <StyledTableCell align="center">
                              <CircularProgressWithLabel value={userProgress} />
                            </StyledTableCell> */}
                            <StyledTableCell align="center">
                              {extraInfo || "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <DeleteOutlineIcon
                                onClick={() => {
                                  setRemoveId(row.user_id);
                                  setOpenDlg(true);
                                }}
                                sx={{ color: "red" }}
                              ></DeleteOutlineIcon>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                      {emptyRows > 3 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={userData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
            <Dialog open={openDlg} onClose={() => setOpenDlg(false)}>
              <DialogTitle id="remove-dlg">{t("admin-remove")}</DialogTitle>
              <DialogActions>
                <Button onClick={() => setOpenDlg(false)}>
                  {t("admin-remove-no")}
                </Button>
                <Button onClick={() => removeRow(removeId)}>
                  {t("admin-remove-yes")}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }
      {
        tabValue === 2 && (
          <Box sx={{ flexGrow: 1, margin: "30px 15px 15px 15px" }}>
            <Paper sx={{ margin: "15px 15px 15px 15px" }}>
              <Toolbar
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                }}
              >
                <Typography variant="h6" id="tableTitle" component="div">
                  Purchase SMS credits
                </Typography>
              </Toolbar>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>

                {/* Left Box for Current Credits */}
                <div style={{ width: '45%', padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '8px', textAlign: 'center' }}>
                  <h3>Current Credits</h3>
                  <p style={{ fontSize: '18px', fontWeight: 'bold' }}>{adminData.sms_count} Credits</p> {/* Example credit count */}
                </div>

                {/* Right Box for Purchase More Credits */}
                <div style={{ width: '45%', padding: '20px', backgroundColor: '#e0f7fa', borderRadius: '8px', textAlign: 'center' }}>
                  <h3>Purchase More Credits</h3>

                  {/* Quantity Input */}
                  <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="quantity" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Credit Quantity:</label>
                    <input
                      type="number"
                      id="quantity"
                      min="1"
                      placeholder="Enter quantity"
                      style={{ width: '80%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
                      onChange={(e) => setQuant(e.target.value)} // Update quantity state
                    />
                  </div>

                  {/* Buy Button */}
                  <button
                    style={{ padding: '10px 20px', backgroundColor: '#00796b', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                    onClick={handleCheckout}
                  >
                    Buy Now
                  </button>
                </div>

              </div>

            </Paper>
          </Box>
        )
      }
      {tabValue === 3 && (
        <Box sx={{ flexGrow: 1, margin: "30px 15px 15px 15px" }}>
          <Paper sx={{ margin: "15px 15px 15px 15px" }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Typography variant="h6" component="div">
                API Access Management
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={generateApiCredentials}
              >
                Generate New API Credentials
              </Button>
            </Toolbar>

            <Box sx={{ p: 3 }}>
              {/* API Credentials Section */}
              {apiCredentials ? (
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    mb: 4,
                    backgroundColor: '#f8fafc',
                    border: '1px solid #e2e8f0'
                  }}
                >
                  <Typography variant="h6" gutterBottom color="primary">
                    Your API Credentials
                  </Typography>

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                      Secret Token:
                    </Typography>
                    <TextField
                      fullWidth
                      value={apiCredentials.secretToken}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <Button
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(apiCredentials.secretToken);
                              setSnackbar({
                                open: true,
                                message: 'Secret token copied!',
                                severity: 'success'
                              });
                            }}
                          >
                            Copy
                          </Button>
                        ),
                      }}
                      sx={{ mb: 2 }}
                    />

                    <Typography variant="subtitle2" gutterBottom>
                      API PIN:
                    </Typography>
                    <TextField
                      value={apiCredentials.pin}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <Button
                            size="small"
                            onClick={() => {
                              navigator.clipboard.writeText(apiCredentials.pin);
                              setSnackbar({
                                open: true,
                                message: 'PIN copied!',
                                severity: 'success'
                              });
                            }}
                          >
                            Copy
                          </Button>
                        ),
                      }}
                      sx={{ mb: 2 }}
                    />
                  </Box>

                  <Alert severity="warning" sx={{ mt: 2 }}>
                    Save these credentials securely! The PIN will not be shown again.
                  </Alert>
                </Paper>
              ) : (
                <Alert severity="info" sx={{ mb: 4 }}>
                  Generate API credentials to start creating appointments programmatically.
                </Alert>
              )}

              {/* API Documentation Section */}
              <ApiDocPaper>
                <Typography variant="h6" gutterBottom>
                  API Usage Instructions
                </Typography>

                <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                  Endpoint Format:
                </Typography>
                <CodeBlock>
                  POST
                  https://sms-termin.de/api/appointments/create/[YOUR_SECRET_TOKEN]
                </CodeBlock>

                <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                  Request Body Example:
                </Typography>
                <CodeBlock>
                  {`{
  "pin": "1234",
  "role_option": "1",
  "date": "2024-03-25T14:30:00",
  "phone": "491234567890",
  "expiry_time": 24,
  "payment_enabled": false,
}`}
                </CodeBlock>

                <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                  Required Fields:
                </Typography>
                <ul>
                  <li>pin: Your 4-digit API PIN</li>
                  <li>role_option: "1", "2"(appointment type)</li>
                  <li>date: Full datetime string</li>
                  <li>phone: Phone number with country code</li>
                  <li>
                    expiry_time: Hours until link expires (positive integer)
                  </li>
                  <li>payment_enabled: Boolean (true/false)</li>
                </ul>

                <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                  Response Example:
                </Typography>
                <CodeBlock>
                  {`{
  "success": true,
  "message": "Appointment created and verification SMS sent",
  "data": {
    "verificationUrl": "https://sms-termin.de/api/verify_phone?token=...",
    "expiryDate": "2024-03-26T14:30:00Z"
  }
}`}
                </CodeBlock>

                <Alert severity="warning" sx={{ mt: 3 }}>
                  Important: The system will automatically send an SMS to the customer with the verification link.
                  One SMS credit will be deducted for each appointment creation.
                </Alert>
              </ApiDocPaper>
            </Box>
          </Paper>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog open={price1Dlg} onClose={() => setPrice1Dlg(false)}>
        <DialogTitle id="price1-dlg">
          Would you like to change the price for option 1?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setPrice1Dlg(false)}>
            {t("admin-remove-no")}
          </Button>
          <Button onClick={() => changePrice_1()}>
            {t("admin-remove-yes")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={price2Dlg} onClose={() => setPrice2Dlg(false)}>
        <DialogTitle id="price2-dlg">
          Would you like to change the price for option 2?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setPrice2Dlg(false)}>
            {t("admin-remove-no")}
          </Button>
          <Button onClick={() => changePrice_2()}>
            {t("admin-remove-yes")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={passDlg} onClose={() => setPassDlg(false)}>
        <DialogTitle id="price2-dlg">
          Would you like to change the password?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setPassDlg(false)}>
            {t("admin-remove-no")}
          </Button>
          <Button onClick={() => changePassword()}>
            {t("admin-remove-yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={remDlg} onClose={() => setRemDlg(false)}>
        <DialogTitle id="rem-dlg">
          Would you like to set the reminders for this customer?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setRemDlg(false)}>
            {t("admin-remove-no")}
          </Button>
          <Button
            onClick={() => handleReminderSubmit()}
          >
            {t("admin-remove-yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={smsTextDlg} onClose={() => setSmsTxtDlg(false)}>
        <DialogTitle id="rem-dlg">
          Would you like to set the reminders sms text for these?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setSmsTxtDlg(false)}>
            {t("admin-remove-no")}
          </Button>
          <Button
            onClick={() => handleReminderTextUpdate()}
          >
            {t("admin-remove-yes")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={stripePubDlg} onClose={() => setStripePubDlg(false)}>
        <DialogTitle id="rem-dlg">
          Is your Stripe Payment key correct?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setStripePubDlg(false)}>
            {t("admin-remove-no")}
          </Button>
          <Button
            onClick={() => handleStripePubUpdate()}
          >
            {t("admin-remove-yes")}
          </Button>
        </DialogActions>
      </Dialog>
      {console.log(formFieldsOpen)}
      <FormFieldsDialog
        open={formFieldsOpen}
        handleClose={() => setFormFieldsOpen(false)}
        adminId={adminId}
      />
    </div >
  );
};

export default EnhancedTable;


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};