import React, { useContext } from "react";
import "./App.css";
import "./i18n";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

import FirstPage from "./components/Dashboard";
import UserPage from "./components/Dashboard/user";
import SignInPage from "./components/Auth/SignInPage";
import SuperAdminPage from "./components/SuperAdmin";
import AdminPage from "./components/Admin";
import StripePage from "./components/StripePayment";
import StripeSuccess from "./components/StripePayment/success";
import UserInfoRegPage from "./components/Auth/UserInfoRegPage";
import SuccessPage from "./components/Utils/SuccessPage";
import OoopsPage from "./components/Utils/OopsPage";
import Game from "./components/Game";
import GameStatusPage from "./components/Game/status";
import SuperAdminSignInPage from "./components/Auth/SuperAdminSignInPage";
import AdminRegisterPage from "./components/Auth/AdminRegister";
import CreditsStripeSuccess from "./components/StripePayment/credits-success";
import RegisterSuccessPage from "./components/Auth/RegisterSuccess";
import { AppContext } from "./context/app-context";

const StyledSelect = styled(Select)({
  backgroundColor: "white",
  color: "black",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "blue",
  },
});

const App = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { adminId, superAdminId, customerId } = useContext(AppContext);
  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };


  const handleLogout = () => {
    localStorage.clear();
    if (adminId) navigate("/admin_login");
    else if (superAdminId) navigate("/super_admin_login");
    else if (customerId) navigate("/");
    window.location.reload();
  };

  const renderRoutes = () => {
    // Add public routes that should always be accessible
    const publicRoutes = (
      <>
        <Route path="/" element={<UserPage />} />
        <Route path="/admin_login" element={<SignInPage />} />
        <Route path="/super_admin_login" element={<SuperAdminSignInPage />} />
        <Route path="/register" element={<AdminRegisterPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/info_regist" element={<UserInfoRegPage />} />
        <Route path="/pay_stripe" element={<StripePage />} />
        <Route path="/stripe_success" element={<StripeSuccess />} />
        <Route path="/oops" element={<OoopsPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </>
    );

    if (superAdminId) {
      return (
        <>
          <Route path="/super_admin_dashboard" element={<SuperAdminPage />} />
          {publicRoutes}
        </>
      );
    }
    
    if (adminId) {
      return (
        <>
          <Route path="/admin" element={<FirstPage />} />
          <Route path="/admin_page" element={<AdminPage />} />
          <Route path="/credits_stripe_success" element={<CreditsStripeSuccess />} />
          <Route path="/admin_register_success" element={<RegisterSuccessPage />} />
          {publicRoutes}
        </>
      );
    }

    if (customerId) {
      return (
        <>
          <Route path="/pay_stripe" element={<StripePage />} />
          <Route path="/stripe_success" element={<StripeSuccess />} />
          <Route path="/oops" element={<OoopsPage />} />
          <Route path="/check_game" element={<GameStatusPage />} />
          <Route path="/playgame" element={<Game />} />
          {publicRoutes}
        </>
      );
    }

    return publicRoutes;
  };

  return (
    <>
      <div className="headerbar">
        <div style={{ color: "white" }}>
          <a
            style={{
              cursor: "pointer",
              color: "white",
              textDecoration: "none",
            }}
            href="/"
          >
            <p>Appointment System</p>
          </a>
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <StyledSelect
            sx={{ height: "30px", border: 0 }}
            value={i18n.language}
            onChange={(e) => handleChangeLanguage(e)}
          >
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="de">Deutsch</MenuItem>
            <MenuItem value="pl">Polski</MenuItem>
            <MenuItem value="ar">العربية</MenuItem>
            <MenuItem value="tr">Türkçe</MenuItem>
            <MenuItem value="ro">Română</MenuItem>
          </StyledSelect>
          {(adminId || superAdminId || customerId) && (
            <Button
              variant="contained"
              style={{ height: "30px", border: 0 }}
              onClick={() => {
                localStorage.clear();  // Clear local storage
                if (adminId) {
                  navigate("/admin_login"); // admin
                } else if (superAdminId) {
                  navigate("/super_admin_login"); // superadmin
                } else if (customerId) {
                  navigate("/"); // customer
                }
                window.location.reload(); // Reload the page after navigation
              }}
            >
              Logout
            </Button>
          )}
        </div>
      </div>
      <Routes>{renderRoutes()}</Routes>
    </>
  );
};

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
