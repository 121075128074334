import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, CssBaseline, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: "calc(100vh - 50px)",
    overflow: "hidden",
    width: '100vw',
    padding: 0,
    margin: 0,
    backgroundColor: '#e0f7fa', // Light cyan background
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
}));

const StyledIcon = styled(CheckCircleIcon)(({ theme }) => ({
    fontSize: 80,
    color: '#4caf50', // Green color
    marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const RegisterSuccessPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    return (
        <StyledContainer component="main" maxWidth="disable">
            <CssBaseline />
            <StyledBox>
                <StyledIcon />
                <Typography component="h1" variant="h5">
                    {/* {t('stripe-success')} */}
                    You are registered!
                </Typography>
                <Typography variant="body1" align="center">
                    {/* {t('stripe-pay-before')} 10 USD {t('stripe-pay-after')} */}
                    Wait for your Super admin to confirm!
                </Typography>
                <StyledButton variant="contained" color="primary" onClick={() => navigate('/admin_login')}>
                    {t('home')}
                </StyledButton>
            </StyledBox>
        </StyledContainer>
    );
};

export default RegisterSuccessPage;
