import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Grid,
  Typography,
  Chip,
  IconButton,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  whiteSpace: 'nowrap',
}));

const StyledChip = styled(Chip)(({ theme, actiontype }) => ({
  fontWeight: 'bold',
  backgroundColor: 
    actiontype === 'USER_DELETED' ? theme.palette.error.light :
    actiontype === 'USER_CREATED' ? theme.palette.success.light :
    actiontype === 'SETTINGS_UPDATED' ? theme.palette.info.light :
    theme.palette.warning.light,
  color: theme.palette.common.white,
}));

const ActivityLogs = () => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [actionType, setActionType] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchLogs = async () => {
    try {
      const params = new URLSearchParams();
      if (startDate) params.append('startDate', startDate);
      if (endDate) params.append('endDate', endDate);
      if (actionType) params.append('actionType', actionType);

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/activity-logs?${params}`);
      setLogs(response.data.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, [startDate, endDate, actionType]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getActionTypeColor = (type) => {
    return type;
  };

  const renderMobileCard = (log) => (
    <Card key={log.id} sx={{ mb: 2, boxShadow: 3 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <StyledChip
              label={log.action_type}
              actiontype={log.action_type}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">
              Admin: {log.admin_email}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {JSON.stringify(log.details, null, 2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              {new Date(log.created_at).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const renderDesktopTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Action Type</StyledTableCell>
            <StyledTableCell>Admin</StyledTableCell>
            <StyledTableCell>Details</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((log) => (
              <TableRow key={log.id} hover>
                <TableCell>
                  <StyledChip
                    label={log.action_type}
                    actiontype={log.action_type}
                    size="small"
                  />
                </TableCell>
                <TableCell>{log.admin_email}</TableCell>
                <TableCell sx={{ maxWidth: 300, wordBreak: 'break-word' }}>
                  {JSON.stringify(log.details, null, 2)}
                </TableCell>
                <TableCell>{new Date(log.created_at).toLocaleString()}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{p: { xs: 1, sm: 3 } }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            Activity Logs
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 3 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <TextField
                  type="date"
                  label="Start Date"
                  value={startDate || ''}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  type="date"
                  label="End Date"
                  value={endDate || ''}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Action Type"
                  value={actionType}
                  onChange={(e) => setActionType(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <IconButton onClick={() => fetchLogs()} color="primary">
                  <RefreshIcon />
                </IconButton>
                <IconButton onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                  setActionType('');
                }} color="secondary">
                  <FilterListIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {isMobile ? (
            <Box>
              {logs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(renderMobileCard)}
            </Box>
          ) : (
            renderDesktopTable()
          )}
          <TablePagination
            component="div"
            count={logs.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActivityLogs; 