import {
  Box,
  Button,
  CssBaseline,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { StyledContainer } from "../../helper/utils";
import { AppContext } from "../../context/app-context";
import { useNavigate } from "react-router-dom";

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const FirstPage = () => {
  const [btnClicked, setBtnClicked] = useState(false);
  const [phone, setPhone] = useState("");
  const navigate = useNavigate()
  const [expiryTime, setExpiryTime] = useState("");
  const [isPaymentEnabled, setIsPaymentEnabled] = useState(true);
  const { t } = useTranslation();
  const paymentLabel = { inputProps: { 'aria-label': 'Enable Payment' } };

  const { adminId } = useContext(AppContext)

  useEffect(() => {
    if (!adminId) {
      navigate("/admin_page");
    }
  }, [adminId, navigate]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setBtnClicked(true);
    const data = new FormData(event.currentTarget);
    console.log(data.get("role_option"), "____")
    const formData = {
      role_option: data.get("role_option"),
      date: data.get("date"),
      phone: phone,
      expiry_time: expiryTime,
      payment_enabled: isPaymentEnabled ? 1 : 0,
      id: adminId
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/register_phone`,
        {
          formData,
        }
      );
      if (response.status === 202 || response.status === 203) {
        alert(response.data.data);
      }
    } catch (err) {
      console.log("error", err);
      alert(err.response.data.data);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="disable">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(255, 251, 240, 0.95)", // Semi-transparent background
          margin: "10px 0",
          padding: 4,
          width: { sm: 400, xs: 1 },
          borderRadius: 1,
          ".form-control": {
            width: "100%",
          },
          ".country-list .search-box": {
            height: 30,
          },
        }}
      >
        <Typography component="h1" variant="h5">
          {t("welcome")}
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="role_option"
            defaultValue="1"
            id="role_option"
          >
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={t("admin-option") + " 1"}
            />
            <FormControlLabel
              sx={{ visibility: "hidden" }}
              control={<Radio />}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={t("admin-option") + " 2"}
            />
          </RadioGroup>
          <LocalizationProvider required dateAdapter={AdapterDayjs}>
            <DemoContainer
              required
              components={["DateTimePicker"]}
              sx={{ marginBottom: "8px", marginTop: "8px" }}
            >
              <DateTimePicker
                required
                id="date"
                name="date"
                label={t("admin-date")}
                ampm={false}
              />
            </DemoContainer>
          </LocalizationProvider>
          <PhoneInput
            id="phone"
            value={phone}
            onChange={(phone) => setPhone(phone)}
            copyNumbersOnly
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              height: "50px",
            }}
            preferredCountries={["fr", "us", "gb"]}
            enableSearch={true}
            containerStyle={{ height: "50px", background: "none" }}
            inputStyle={{ height: "50px", background: "none", width: "100%" }}
          />
          <TextField
            id="expiry"
            label="Expiration Time (hours)"
            variant="outlined"
            style={{ height: "50px", background: "none", width: "100%", marginTop: "10px" }}
            value={expiryTime}
            onChange={(e) => setExpiryTime(e.target.value)}
          />
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <p>Enable Payment</p>
            <Switch
              id="payment"
              {...paymentLabel}
              checked={isPaymentEnabled}
              onChange={(e) => setIsPaymentEnabled(e.target.checked)}
            />
          </div>

          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={btnClicked}
          >
            {t("submit")}
          </StyledButton>
        </StyledForm>
      </Box>
    </StyledContainer>
  );
};

export default FirstPage;
