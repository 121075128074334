import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Container, CssBaseline, Box, Typography, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: "calc(100vh - 50px)",
  overflow: "hidden",
  width: '100vw',
  padding: 0,
  margin: 0,
  backgroundColor: '#e0f7fa', // Light cyan background
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const StyledIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: 80,
  color: '#4caf50', // Green color
  marginBottom: theme.spacing(2),
}));

const StyledFailedIcon = styled(CheckCircleIcon)(({ theme }) => ({
  fontSize: 80,
  color: '#f44336', // Red color
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const SuccessPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [session, setSession] = useState(null);
  const [payStatus, setPayStatus] = useState(3);//loading
  const phone = localStorage.getItem('phone');
  const role_option = localStorage.getItem('role_option');

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    const payload = {
      session_id: sessionId,
      phone: phone
    };

    const checkPay = async () => {
      if (sessionId) {
        try {
          // Log for debugging
          console.log('Checking payment with sessionId:', sessionId);

          const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripe/checkout-session`, { payload });

          // Log the response for debugging
          console.log('API Response:', response.data);
          console.log('Payment Status:', response.data.data.payment_status);
          console.log('Payment Complete Status:', response.data.data.status);

          // We will no longer rely on response.status, but on the payment_status and status from the response data
          const paymentStatus = response.data.data.payment_status;
          const status = response.data.data.status;

          console.log("paymentStatus:", paymentStatus, "status:", status)

          if (paymentStatus === "paid" && status === "complete") {
            console.log('Payment successful, updating status to success');
            setPayStatus(1);  // Success
            setSession(response.data.data);
            localStorage.setItem('payment', 'true');
          } else {
            console.log('Payment failed or incomplete');
            setPayStatus(0);  // Failure or incomplete
            setSession(response.data.data);
          }
        } catch (err) {
          console.error('Error:', err);
          setPayStatus(0);  // Error state
        }
      }
    };

    checkPay();
  }, [location]);


  return (
    <StyledContainer component="main" maxWidth="disable">
      <CssBaseline />
      <StyledBox>
        {payStatus === 3 && (
          <>
            <CircularProgress />
            <br />
            <Typography component="h1" variant="h5" sx={{ marginTop: 2 }}>
              {t('stripe-loading')}
            </Typography>
            <Typography variant="body1" align="center" sx={{ marginTop: 1 }}>
              {t('stripe-wait')}
            </Typography>
          </>
        )}
        {(payStatus == 1) && (
          <StyledIcon />
        )}
        {payStatus == 0 && (
          <StyledFailedIcon />
        )}
        {payStatus == 0 && (
          <Typography component="h1" variant="h5">
            {t('stripe-failed')}
          </Typography>
        )}
        {payStatus == 1 && (
          <Typography component="h1" variant="h5">
            {t('stripe-success')}
          </Typography>
        )}
        {payStatus == 2 && (
          <>
            <CircularProgress />
            <br />
            <Typography component="h1" variant="h5">
              Hold tight, your order is being processed. We will message or email you when your order succeeds.
            </Typography>
          </>
        )}
        {(payStatus == 1) && (
          <Typography variant="body1" align="center">
            {t('stripe-pay-before')} {session.amount_total / 100} {session.currency.toUpperCase()} {t('stripe-pay-after')}
          </Typography>
        )}
        {payStatus == 0 && (
          <Typography variant="body1" align="center">
            {t('stripe-retry')}
          </Typography>
        )}
        <div style={{ display: 'flex', gap: 10 }}>
          {(payStatus == 1 && role_option == '2') && (
            <StyledButton variant="contained" color="primary" href="/playgame">
              {t('game')}
              {console.log("game page")}
            </StyledButton>
          )}
          {(payStatus == 1 && role_option == '1') && (
            <StyledButton variant="contained" color="primary" href="/success">
              {t('game-next')}
              {console.log("game pagesdfsd")}
            </StyledButton>
          )}
          {(payStatus == 1 || payStatus == 2) && (
            <StyledButton variant="contained" color="primary" href="/">
              {t('home')}
              {console.log("game pagesdfsde3")}
            </StyledButton>
          )}
        </div>
      </StyledBox>
    </StyledContainer>
  );
}

export default SuccessPage;








