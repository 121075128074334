import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";

import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TextField, Box, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import ActivityLogs from './ActivityLogs';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    textAlign: "left",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    maxWidth: "150px",
    margin: "auto",
    position: "absolute",
    right: 5,
    "& .MuiOutlinedInput-root": {
        borderRadius: "50px",
        paddingRight: theme.spacing(2),
    },
    "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1.5, 4),
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const EnhancedTableHead = (props) => {
    const { t } = useTranslation();

    const headCells = [
        {
            id: "role",
            numeric: true,
            disablePadding: false,
            label: "Role",
        },
        {
            id: "email",
            numeric: true,
            disablePadding: false,
            label: "Email",
        },
        {
            id: "sms_count",
            numeric: true,
            disablePadding: false,
            label: "SMS Credit",
        },
        {
            id: "price_1",
            numeric: true,
            disablePadding: false,
            label: 'Option 1(Price)',
        },
        {
            id: "price_2",
            numeric: true,
            disablePadding: false,
            label: "Option 2(Price)"
        },
        {
            id: "sms_count",
            numeric: true,
            disablePadding: false,
            label: "SMS Count"
        },
        {
            id: "rem_1",
            numeric: true,
            disablePadding: false,
            label: "Reminder One"
        },
        {
            id: "rem_2",
            numeric: true,
            disablePadding: false,
            label: "Reminder Two",
        },
        {
            id: "rem_3",
            numeric: true,
            disablePadding: false,
            label: "Reminder Three",
        },
        // {
        //     id: "rem_1_per",
        //     numeric: true,
        //     disablePadding: false,
        //     label: "Reminder One (Time %)"
        // },
        // {
        //     id: "rem_2_per",
        //     numeric: true,
        //     disablePadding: false,
        //     label: "Reminder Two (Time %)",
        // },
        // {
        //     id: "rem_3_per",
        //     numeric: true,
        //     disablePadding: false,
        //     label: "Reminder Three (Time %)",
        // },
        {
            id: "ph_veri_txt",
            numeric: true,
            disablePadding: false,
            label: "Verify Reminder SMS",
        },
        {
            id: "no_reg_txt",
            numeric: true,
            disablePadding: false,
            label: "Register Reminder SMS",
        },
        {
            id: "active",
            numeric: true,
            disablePadding: false,
            label: "Active Status",
        },
        {
            id: "action",
            numeric: true,
            disablePadding: false,
            label: "admin-action",
        },
    ];

    return (
        <TableHead>
            <StyledTableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align="center"
                        padding={headCell.disablePadding ? "none" : "normal"}
                    >
                        {headCell.label}
                    </StyledTableCell>
                ))}
            </StyledTableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    rowCount: PropTypes.number.isRequired,
};

EnhancedTableHead.propTypes = {
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTable = () => {
    const { t } = useTranslation();

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [superAdminData, setSuperAdminData] = useState([]);
    const [filterKey, setFiliterKey] = useState("");
    const [adminData, setAdminData] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [superAdminPassword, setSuperAdminPassword] = useState("");
    const [passDlg, setPassDlg] = useState(false);
    const [openDlg, setOpenDlg] = useState(false);
    const [openUpdDlg, setOpenUpdDlg] = useState(false);
    const [removeId, setRemoveId] = useState(0);
    const [smsPriceDlg, setSmsPriceDlg] = useState(false);
    const [sms_price, setSmsPrice] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const super_admin = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/get_super_admin_info`
                );
                if (super_admin.status === 200) {
                    setSuperAdminData(super_admin.data.data[0]);
                } else {
                    alert(t("admin-error"));
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const admin_info = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/get_all_admin_info`
                );
                console.log("🚀 ~ fetchData ~ );:", admin_info)
                if (admin_info.status === 200) {
                    setPage(0);
                    setAdminData(admin_info.data.data);
                } else {
                    alert("Error!");
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [filterKey]);

    const changeSmsPrice = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/changeSmsPrice`,
                {
                    sms_price,
                    id: superAdminData.id
                }
            );
            if (response.status === 200) {
                setSmsPriceDlg(false);
                alert(t("admin-pricechagned1"));
            } else {
                alert(t("admin-error"));
            }
        } catch (err) {
            alert(err);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const changePassword = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/change_super_password`,
                {
                    superAdminPassword,
                    id: superAdminData.id
                }
            );
            if (response.status === 200) {
                setPassDlg(false);
                alert(t("admin-passchanged"));
            } else {
                alert(t("admin-error"));
            }
        } catch (err) {
            alert(err);
        }
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - adminData.length) : 0;

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };
    const stableSort = (array, comparator) => {
        if (!Array.isArray(array)) {
            console.error("Expected an array but got", typeof array);
            return [];
        }

        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const visibleRows = useMemo(() => {
        console.log("Admin Data:", adminData); // Log user data
        return stableSort(adminData, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
    }, [order, orderBy, page, rowsPerPage, adminData]);

    const hanldeTabValue = (event, v) => {
        setTabValue(v);
    };

    const serachUser = async (event) => {
        if (event.key === "Enter") {
            setFiliterKey(event.target.value);
        }
    };

    const removeRow = async (row) => {
        try {
            const admin_info = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/remove_admin`,
                {
                    row,
                }
            );
            if (admin_info.status === 200) {
                setOpenDlg(false);
                setAdminData(admin_info.data.data);
                alert("Deleted exactly!");
            } else {
                alert(t("admin-error"));
            }
        } catch (err) {
            alert(err);
        }
    };

    const updateRow = async (row) => {
        try {
            const admin_info = await axios.post(
                `${process.env.REACT_APP_API_URL}/user/activate_admin`,
                {
                    row,
                }
            );
            if (admin_info.status === 200) {
                setOpenUpdDlg(false);
                setAdminData(admin_info.data.data);
                alert("Activated exactly!");
            } else {
                alert(t("admin-error"));
            }
        } catch (err) {
            alert(err);
        }
    };

    return (
        <div style={{ position: "relative", top: "60px" }}>
            <AppBar
                position="static"
                sx={{ marginRight: "15px", marginLeft: "15px", width: "auto" }}
            >
                <Tabs
                    sx={{ paddingLeft: "10px" }}
                    value={tabValue}
                    onChange={(e, v) => hanldeTabValue(e, v)}
                    indicatorColor="secondary"
                    textColor="inherit"
                    aria-label="full width tabs example"
                >
                    <Tab label="Super Admin Settings" value={0} />
                    <Tab label="Activity Logs" value={1} />
                </Tabs>
            </AppBar>
            <Box sx={{ flexGrow: 1, margin: "30px 15px 15px 15px" }}>
                <Paper sx={{ padding: "15px 15px 15px 15px" }}>
                    <Typography
                        component="h1"
                        variant="h5"
                        align="left"
                        marginBottom={"5px"}
                    >
                        {/* {t("admin-header")} */}
                        Set Up Super Admin Information
                    </Typography>
                    <Divider sx={{ marginBottom: "25px" }}></Divider>
                    <Grid
                        container
                        rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                        <Grid xs={12} md={4}>
                            <Item>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={t("admin-password")}
                                    type="password"
                                    id="password"
                                    autoComplete="password"
                                    onChange={(e) => {
                                        setSuperAdminPassword(e.target.value);
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setPassDlg(true);
                                    }}
                                >
                                    {t("admin-set")}
                                </Button>
                            </Item>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Item>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="sms_price"
                                    label={'Price per SMS is ' + superAdminData.sms_price}
                                    defaultValue={superAdminData.sms_price}
                                    type="number"
                                    id="sms_price"
                                    autoComplete="Price Per SMS"
                                    onChange={(e) => {
                                        setSmsPrice(e.target.value);
                                    }}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setSmsPriceDlg(true);
                                    }}
                                >
                                    {t("admin-set")}
                                </Button>
                            </Item>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            {
                tabValue === 0 && (
                    <>
                        <Box sx={{ width: "100%" }}>
                            <Paper sx={{ margin: "15px 15px 15px 15px" }}>
                                <Toolbar
                                    sx={{
                                        pl: { sm: 2 },
                                        pr: { xs: 1, sm: 1 },
                                    }}
                                >
                                    <Typography variant="h6" id="tableTitle" component="div">
                                        {/* {t("admin-list")} */}
                                        List of Admins
                                    </Typography>
                                    <StyledTextField
                                        variant="outlined"
                                        placeholder={t("admin-search")}
                                        onKeyDown={(e) => serachUser(e)}
                                    />
                                </Toolbar>
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? "small" : "medium"}
                                    >
                                        <EnhancedTableHead rowCount={adminData.length} />
                                        <TableBody>
                                            {visibleRows.map((row, index) => {
                                                return (
                                                    <StyledTableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.admin_id}
                                                        sx={{ cursor: "pointer" }}
                                                    >
                                                        <StyledTableCell align="center">
                                                            {row?.role}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.email}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.sms_count}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.price_1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.price_2}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.sms_count}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.rem_1}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.rem_2}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.rem_3}
                                                        </StyledTableCell>
                                                        {/* <StyledTableCell align="center">
                                                            {row?.rem_1_per}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.rem_2_per}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.rem_3_per}
                                                        </StyledTableCell> */}
                                                        <StyledTableCell align="center">
                                                            {row.ph_veri_txt ? row.ph_veri_txt : "N/A"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row.no_reg_txt ? row.no_reg_txt : "N/A"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row?.active === 1 ? (
                                                                <CheckCircleIcon sx={{ color: "green" }} />
                                                            ) :
                                                                (
                                                                    <CancelIcon sx={{ color: "red" }} />
                                                                )}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center" style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 8, flexDirection: "column" }}>
                                                            <UpdateIcon
                                                                onClick={() => {
                                                                    setRemoveId(row.id);
                                                                    setOpenUpdDlg(true);
                                                                }}
                                                                sx={{ color: "blue" }}
                                                            />
                                                            <DeleteOutlineIcon
                                                                onClick={() => {
                                                                    setRemoveId(row.id);
                                                                    setOpenDlg(true);
                                                                }}
                                                                sx={{ color: "red" }}
                                                            />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow
                                                    style={{
                                                        height: (dense ? 33 : 53) * emptyRows,
                                                    }}
                                                >
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={adminData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Box>
                        <Dialog open={openDlg} onClose={() => setOpenDlg(false)}>
                            <DialogTitle id="remove-dlg">{t("admin-remove")}</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => setOpenDlg(false)}>
                                    {t("admin-remove-no")}
                                </Button>
                                <Button onClick={() => removeRow(removeId)}>
                                    {t("admin-remove-yes")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog open={openUpdDlg} onClose={() => setOpenUpdDlg(false)}>
                            <DialogTitle id="remove-dlg">Would you like to activate this admin?</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => setOpenUpdDlg(false)}>
                                    {t("admin-remove-no")}
                                </Button>
                                <Button onClick={() => updateRow(removeId)}>
                                    {t("admin-remove-yes")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )
            }
            {tabValue === 1 && (
                <Box sx={{ p: 3 }}>
                    <ActivityLogs />
                </Box>
            )}
            <Dialog open={passDlg} onClose={() => setPassDlg(false)}>
                <DialogTitle id="pass-dlg">
                    Would you like to change the password?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setPassDlg(false)}>
                        {t("admin-remove-no")}
                    </Button>
                    <Button onClick={() => changePassword()}>
                        {t("admin-remove-yes")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={smsPriceDlg} onClose={() => setSmsPriceDlg(false)}>
                <DialogTitle id="price1-dlg">
                    Would you like to change the price per SMS?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setSmsPriceDlg(false)}>
                        {t("admin-remove-no")}
                    </Button>
                    <Button onClick={() => changeSmsPrice()}>
                        {t("admin-remove-yes")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default EnhancedTable;
