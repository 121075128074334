import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, CssBaseline, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { StyledContainer } from "../../helper/utils";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripePage = () => {
  const { t } = useTranslation();
  const role_option = localStorage.getItem("role_option");
  const phone = localStorage.getItem("phone");
  const date = localStorage.getItem("date");
  const adminId = localStorage.getItem("payid");
  const [payId, setPayId] = useState("")
  console.log("🚀 ~ StripePage ~ payId:", payId)
  const payload = {
    phone,
    role_option,
    date,
    payId,
    adminId
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log(adminId)
      try {
        const payidbyid = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/get_pay_id`, { adminId }
        );
        if (payidbyid.status === 200) {
          console.log(adminId)
          setPayId(payidbyid.data.data);
        } else {
          alert(t("admin-error"));
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleCheckout = async () => {
    const stripe = await stripePromise;
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`,
      {
        payload,
      }
    );
    const { id } = response.data;
    console.log("🚀 ~ handleCheckout ~ id:", id)

    const result = await stripe.redirectToCheckout({
      sessionId: id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="disable">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(255, 251, 240, 0.95)", // Semi-transparent background
          margin: "10px 0",
          padding: 4,
          width: { sm: 400, xs: 1 },
          borderRadius: 1,
        }}
      >
        <Typography component="h1" variant="h5">
          {t("stripe-title")}
        </Typography>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleCheckout}
        >
          {t("stripe-btn")}
        </StyledButton>
      </Box>
    </StyledContainer>
  );
};

export default StripePage;
