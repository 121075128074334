import axios from "axios";
import { styled } from "@mui/system";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StyledContainer } from "../../helper/utils";
import { Box, Button, CssBaseline, TextField, Typography } from "@mui/material";

const StyledForm = styled("form")(({ theme }) => ({
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
}));

const AdminRegisterPage = () => {
    const { t } = useTranslation();
    const [pas, setPas] = useState("");
    const [em, setEm] = useState("");
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = { password: pas, email: em };

        try {
            console.log("Submitting form data:", formData);

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/admin_register`,
                { formData }
            );

            console.log("Received response from backend:", response);

            if (response.status === 200) {
                const { data, stripeOAuthURL } = response.data;
                console.log("Registration successful, data:", data);
                console.log("Stripe OAuth URL:", stripeOAuthURL);

                window.open(stripeOAuthURL, '_blank');
                if (data.active === 1) {
                    navigate('/admin_register_success');
                } else {
                    navigate('/admin_register_success');
                }
            } else {
                console.error("Unexpected response status:", response.status);
                navigate('/oops');
            }
        } catch (err) {
            console.error('Registration failed:', err);
            navigate('/oops');
        }
    };

    return (
        <StyledContainer component="main" maxWidth="disable">
            <CssBaseline />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 251, 240, 0.95)", // Semi-transparent background
                    margin: "10px 0",
                    padding: 4,
                    width: { sm: 400, xs: 1 },
                    borderRadius: 1,
                    ".form-control": {
                        width: "100%",
                    },
                    ".country-list .search-box": {
                        height: 30,
                    },
                }}
            >
                <Typography component="h1" variant="h5">
                    {/* {t("welcome")} */}
                    Register Admin
                </Typography>
                <StyledForm onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label={"Email"}
                        type="email"
                        id="email"
                        autoComplete="email"
                        value={em}
                        onChange={(e) => setEm(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={'Password'}
                        type="password"
                        id="password"
                        autoComplete="password"
                        value={pas}
                        onChange={(e) => setPas(e.target.value)}
                    />
                    <StyledButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        {t("submit")}
                    </StyledButton>
                </StyledForm>
                <p>Already have an account?
                    <span style={{ color: "red", marginLeft: "4px", cursor: "pointer" }} onClick={() => navigate('/admin_login')}>
                        Login
                    </span>
                </p>
            </Box>
        </StyledContainer>
    );
};

export default AdminRegisterPage;
