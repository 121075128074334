import { Box, Button, CssBaseline, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledContainer } from "../../helper/utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/app-context";

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "rgba(255, 251, 240, 0.95)", // Semi-transparent background
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const SignInPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [em, setEmail] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/login`,
        {
          formData,
        }
      );
      if (response.status === 200 && response.data.data.active === 1) {
        localStorage.setItem("authAdmin", true)
        localStorage.setItem("adminId", response.data.data.id);
        localStorage.setItem("admin-email", em)
        localStorage.setItem("authToken", true)
        navigate("/admin_page") // Redirect on successful login
        window.location.reload();
      } else if (response.status === 200 && response.data.data.active === 0) {
        navigate("/admin_register_success")
      } else {
        window.location.href = "/oops";
      }
    } catch (err) {
      window.location.href = "/oops";
      console.log(err);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="disable">
      <CssBaseline />
      <StyledBox>
        <Typography component="h1" variant="h5">
          {t("welcome-admin")}
        </Typography>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label={"Email"}
            type="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("admin-password")}
            type="password"
            id="password"
            autoComplete="password"
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            {t("sign-admin")}
          </StyledButton>
        </StyledForm>
        <p>Don't have an account?
          <span style={{ color: "red", marginLeft: "4px", cursor: "pointer" }} onClick={() => navigate('/register')}>
            Register
          </span>
        </p>
      </StyledBox>
    </StyledContainer>
  );
};

export default SignInPage;
