import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, CssBaseline, Box, Typography, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: "calc(100vh - 50px)",
    overflow: "hidden",
    width: '100vw',
    padding: 0,
    margin: 0,
    backgroundColor: '#e0f7fa', // Light cyan background
}));

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
}));

const StyledIcon = styled(CheckCircleIcon)(({ theme }) => ({
    fontSize: 80,
    color: '#4caf50', // Green color
    marginBottom: theme.spacing(2),
}));

const StyledFailedIcon = styled(CheckCircleIcon)(({ theme }) => ({
    fontSize: 80,
    color: '#f44336', // Red color
    marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const CreditsStripeSuccess = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [session, setSession] = useState(null);
    const [payStatus, setPayStatus] = useState(3);//loading
    const email = localStorage.getItem('admin-email');

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const sessionId = query.get('session_id');
        const payload = {
            session_id: sessionId,
            email: email
        };

        const checkPay = async () => {
            if (sessionId) {
                try {
                    // Log for debugging
                    console.log('Checking payment with sessionId:', sessionId);

                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripe/credit-checkout-session`, { payload });

                    // Log the response for debugging
                    console.log('API Response:', response.data);
                    console.log('Payment Status:', response.data.data.payment_status);
                    console.log('Payment Complete Status:', response.data.data.status);

                    const paymentStatus = response.data.data.payment_status;
                    const status = response.data.data.status;

                    if (paymentStatus === "paid" && status === "complete") {
                        try {
                            const smsCount = response.data.data.metadata.quantity;
                            const admin_Id = response.data.data.metadata.id;

                            console.log("Updating SMS count to:", smsCount);

                            const updateResponse = await axios.post(`${process.env.REACT_APP_API_URL}/admin/change_sms_count`, { payload: { sms_count: smsCount, adminId: admin_Id } });

                            console.log("SMS count updated:", updateResponse.data);
                        } catch (error) {
                            console.log("🚀 ~ checkPay ~ error in updating SMS count:", error);
                        }

                        console.log('Payment successful, updating status to success');
                        setPayStatus(1);
                        setSession(response.data.data);
                        localStorage.setItem('payment', 'true');
                    } else {
                        console.log('Payment failed or incomplete');
                        setPayStatus(0);
                        setSession(response.data.data);
                    }
                } catch (err) {
                    console.error('Error:', err);
                    setPayStatus(0);
                }
            }
        };

        checkPay();
    }, [location]);



    return (
        <StyledContainer component="main" maxWidth="disable">
            <CssBaseline />
            <StyledBox>
                {payStatus === 3 && (
                    <>
                        <CircularProgress />
                        <br />
                        <Typography component="h1" variant="h5" sx={{ marginTop: 2 }}>
                            {t('stripe-loading')}
                        </Typography>
                        <Typography variant="body1" align="center" sx={{ marginTop: 1 }}>
                            {t('stripe-wait')}
                        </Typography>
                    </>
                )}
                {(payStatus === 1) && (
                    <StyledIcon />
                )}
                {payStatus === 0 && (
                    <StyledFailedIcon />
                )}
                {payStatus === 0 && (
                    <Typography component="h1" variant="h5">
                        {t('stripe-failed')}
                    </Typography>
                )}
                {payStatus === 1 && (
                    <Typography component="h1" variant="h5">
                        {t('stripe-success')}
                    </Typography>
                )}
                {payStatus === 2 && (
                    <>
                        <CircularProgress />
                        <br />
                        <Typography component="h1" variant="h5">
                            Hold tight, your order is being processed. We will message or email you when your order succeeds.
                        </Typography>
                    </>
                )}
                {(payStatus === 1) && (
                    <Typography variant="body1" align="center">
                        {t('stripe-pay-before')} {session.amount_total / 100} {session.currency.toUpperCase()} {t('stripe-pay-after')}
                    </Typography>
                )}
                {payStatus === 0 && (
                    <Typography variant="body1" align="center">
                        {t('stripe-retry')}
                    </Typography>
                )}
                <Button variant='contained' color='primary' onClick={() => navigate("/admin_page")}>Admin Home</Button>
            </StyledBox>
        </StyledContainer>
    );
}

export default CreditsStripeSuccess;
