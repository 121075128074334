import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Box,
  Typography,
  Checkbox,
  Grid,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    }
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  backgroundColor: 'rgba(255, 251, 240, 0.95)',
  borderRadius: theme.spacing(1),
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4]
  }
}));

const FormFieldsDialog = ({ open, handleClose, adminId }) => {
  const { t } = useTranslation();
  const [fieldConfig, setFieldConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchFieldConfig();
    }
  }, [open]);

  const fetchFieldConfig = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/field_config/${adminId}`);
      setFieldConfig(response.data.config);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching field config:', error);
    }
  };

  const handleToggleField = (fieldName) => {
    setFieldConfig({
      ...fieldConfig,
      [fieldName]: {
        ...fieldConfig[fieldName],
        enabled: !fieldConfig[fieldName].enabled
      }
    });
  };

  const handleToggleRequired = (fieldName) => {
    setFieldConfig({
      ...fieldConfig,
      [fieldName]: {
        ...fieldConfig[fieldName],
        required: !fieldConfig[fieldName].required
      }
    });
  };

  const handleCustomLabelChange = (fieldName, value) => {
    setFieldConfig({
      ...fieldConfig,
      [fieldName]: {
        ...fieldConfig[fieldName],
        label: value
      }
    });
  };

  const handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/admin/field_config/${adminId}`, {
        config: fieldConfig
      });
      alert(t('admin-fields-saved'));
      handleClose();
    } catch (error) {
      console.error('Error saving field config:', error);
      alert(t('admin-error'));
    }
  };

  if (loading) {
    return null;
  }

  return (
    <StyledDialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle sx={{ 
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 600,
        color: '#2c3e50',
        pb: 3
      }}>
        {t('admin-field-config')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {Object.entries(fieldConfig).map(([fieldName, config]) => (
            <Grid item xs={12} key={fieldName}>
              <StyledPaper elevation={2}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                  gap: 2,
                  p: 1
                }}>
                  <Typography sx={{ 
                    minWidth: '120px',
                    fontWeight: 500,
                    color: '#34495e'
                  }}>
                    {fieldName.startsWith('custom') ? 'Custom Field' : t(`admin-field-${fieldName}`)}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    flexWrap: 'wrap',
                    gap: 2,
                    alignItems: 'center'
                  }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={config.enabled}
                          onChange={() => handleToggleField(fieldName)}
                          color="primary"
                        />
                      }
                      label="Enable"
                    />
                    {config.enabled && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={config.required}
                            onChange={() => handleToggleRequired(fieldName)}
                            color="primary"
                          />
                        }
                        label={t('admin-field-required')}
                      />
                    )}
                    {fieldName.startsWith('custom') && config.enabled && (
                      <TextField
                        label={t('admin-field-label')}
                        value={config.label}
                        onChange={(e) => handleCustomLabelChange(fieldName, e.target.value)}
                        size="small"
                        sx={{ minWidth: '200px' }}
                      />
                    )}
                  </Box>
                </Box>
              </StyledPaper>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button 
          onClick={handleClose}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          {t('admin-cancel')}
        </Button>
        <Button 
          onClick={handleSave}
          variant="contained"
        >
          {t('admin-save')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default FormFieldsDialog; 